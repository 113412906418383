.filter-section {
  width: 100%;
}

.filter-section-header {
  display: flex;
  justify-content: space-between;
  padding: 0.3125rem 1.25rem 0.625rem 1.25rem;
}

.filter-section > div > p {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(87, 87, 87);
  margin-bottom: 0.625rem;
}

.filter-section-header svg {
  width: 0.625rem;
  height: 0.625rem;
  fill: #2d2d2d;
  cursor: pointer;
}

.filter-section > .date-picker-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;
}


.date-filter-item {
  padding: 0.625rem 1.25rem;
  display: block;
  width: 100%;
  cursor: pointer;
  transition: background-color .4s;
}

.date-picker-item > button {
  width: 100%;
  padding: 0.5rem 0.9375rem;
  font-size: 0.9375rem;
  margin-top: 0.625rem;
  font-weight: 600;
}

.removable-filter-item {
  padding: 0 1.25rem;
  display: block;
  width: 100%;
  transition: background-color .4s;
}

.date-filter-item:hover {
  background-color: #4e5f6e0c;
}

.date-filter-item > div, 
.removable-filter-item > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000c4;
}

.removable-filter-item > div > span > svg {
  width: 0.625rem;
  height: 0.625rem;
  cursor: pointer;
  fill: #464747;
}

.removable-filter-item > div > span > svg:hover {
  fill: red;
}

.project-filter-item > span {
  display: block;
  background: #fff;
  border: 0.0625rem solid #c9d7df;
  color: #32485c;
  padding: 0.3125rem 0.5rem;
  margin: 0.3125rem 0;
  border-radius: 0.1875rem;
}

.type-filter-item {
  padding: 0.3125rem 1.25rem;
  display: flex;
  align-items: center;
}

.type-title {
  position: relative;
  left: 0.5rem;
  color: rgb(78, 78, 78);
  font-size: 1rem;
}

.person-filter-item {
  display: flex;
  align-items: center;
  background: #fff;
  border: 0.0625rem solid #c9d7df;
  color: #32485c;
  padding: 0.3125rem 0.5rem;
  margin: 0.3125rem 0;
  border-radius: 0.1875rem;
  font-size: 0.9375rem;
}

.profile-pic {
  margin-right: 0.3125rem;
}