.calendar-datepicker {
  width: 100%;
}
.calendar-datepicker__header {
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
}
.calendar-datepicker__caption {
  margin: 0 auto;
  font-weight: 700;
  color: black;
  color: #4e4e4e;
  font-size: 0.9375rem;
}
.calendar-datepicker__prev-month, 
.calendar-datepicker__next-month {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    fill: rgba(0, 0, 0, 0.315);
  }
}
.calendar-datepicker__prev-month {
  transform: rotate(180deg);
}

.calendar-datepicker__weekdays-row {
  display: flex;
  flex: 1 1 auto;
  margin: 0.9375rem 0 0.9375rem 0;

  span {
    text-align: center;
    flex: 1 1 14%;
    font-size: 0.875rem;
    font-weight: 600;
    color: #000000c4;
  }
}

.calendar-datepicker__dates-row {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 0.125rem;
}

.calendar-datepicker__date {
  flex: 1 1 14%;
  text-align: center;
  border-radius: 0.1875rem;
  padding: 0.3125rem 0;
  cursor: pointer;
  color: rgb(78, 78, 78);
  font-size: 0.8125rem;

  &:hover {
    background: #c9d7df;
    color: #283542;
  }

  &--today {
    font-weight: 600;
    color: black;
    text-decoration: underline;
  }

  &--selected {
    background-color: #216ba5;
    color: #fff;
    font-weight: bold;

    &:hover {
      background-color: #1d5d90;
      color: #fff;
    }
  }
}