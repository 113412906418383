.default-dropdown {
  border-radius: 4px;
  width: 100%;
  background-image: linear-gradient(-180deg,#fff,#f5f8f9 99%);
  border: 1px solid #c9d7df;
  box-shadow: 0px 1px 0px 0px #c9d7df;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 10.625rem;
}

.default-dropdown__item {
  padding: 0.625rem 0.625rem;
  border-bottom: 1px solid #c9d7df;
  cursor: pointer;
  color: #000000a8;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #c9d7df;
    color: #000000;
  }

  &--selected {
    background: #c9d7df;
    color: #000000;
  }
}