.modal {
  position: fixed;
  z-index: 2147482999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;

  &--open {
    display: block;
  }
}

.modal__container {
  background: rgba(40, 53, 66, 0.88);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;

  & > div {
    display: flex;

    & > * + * {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }
}

.modal__buttons {
  height: 1.875rem;
  width: 1.875rem;

  svg {
    stroke: white;
    height: 1.875rem;
    width: 2rem;
    border-radius: 0.1875rem;
    cursor: pointer;
    transition: background-color .2s;
    padding: 0.125rem;

    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}