.meetings-container {
  width: 100%;
}

.meetings-content {
  display: flex;
}

.content {
  padding: 0 10rem 1.875rem 10rem;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - (3.75rem + 2.9375rem));
  height: calc(100vh - (2.9375rem));
}

.content-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #283542;
  margin-top: 1.9rem;
  display: none;
}

.content-header {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  padding-bottom: 0.75rem;
}
.content-header::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -2rem;
  right: -2rem;
  width: 100%;
  margin: 0 auto;
  height: 1.6rem;
  background: linear-gradient(180deg,#f9f9f9 0,rgba(240,244,247,0));
}
.content-header > * {
  margin-bottom: 0.9375rem;
}

.session-selector {
  display: flex;
  padding-top: 1.25rem;
  align-items: center;
}

.session-selector > .meeting-session {
  margin-right: 1.25rem;
}

.meeting-session > a {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 0.9375rem;
  color: #3e4852;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.meeting-session > a:hover {
  color: black;
}

.selected-session, .selected-session:hover {
  border-bottom: 0.0625rem dotted #696565 !important;
}

.button {
  border-radius: 0.1875rem;
  background: #0f6694;
  font-weight: bold;
  color: white;
  padding: 0.625rem 0.5rem;
  font-size: 0.875rem;
  display: flex;
  width: 7.8125rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
}

.button > svg {
  width: 0.75rem;
  height: 0.75rem;
  fill: white;
  margin-right: 0.5rem;
}

.button:hover {
  background: #0e5982;
}

.meeting-list {
  font-size: 1rem;
  padding: 1.25rem 1.25rem;
  /*margin: 1.25rem 0;*/
  border-radius: 0.125rem;
  background: white;
  border: 0.0625rem solid #bccdd7;
}

.meeting-list > .meeting-item {
  margin-top: 1.25rem;
}

.meeting-count {
  margin-right: 0.625rem;
  font-size: 1.375rem;
  font-weight: 700;
}

.meeting-date {
  font-size: 1.125rem;
  font-weight: 600;
}

.meeting-item {
  cursor: pointer;
  padding: 1.25rem 1.25rem;
  border-radius: 0.25rem;
  display: block;
}
.meeting-item:hover {
  background: #1682b80a;
  background: #4e5f6e0c;
}

.meeting-info {
  display: flex;
}

.meeting-info > div {
  margin-right: 2.5rem;
}

.meeting-type, .meeting-activity {
  width: 5rem;
}

.meeting-time {
  width: 9.0625rem;
}

.meeting-participants {
  margin-top: 1.5625rem;
}

.meeting-participants > div {
  display: flex;
  margin-top: 0.9375rem;
}

.participant {
  display: flex;
  align-items: center;
  margin-right: 1.5625rem;
}

.participant > div:first-child {
  margin-right: 0.625rem;
}

.participant-metadata > p:first-child {
  font-size: 0.9375rem;
}
.participant-metadata > p:nth-child(2) {
  font-size: 0.875rem;
  /*font-style: italic;*/
  color: rgb(92, 92, 92);
}

.meeting-item-header {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: rgb(70, 70, 70);
  margin-bottom: 0.625rem;
}

.meeting-confirmation {
  margin-top: 1.875rem;
  color: #717171;
  font-size: 0.9375rem;
}

.sidebar {
  position: fixed;
  width: 22%;
  min-width: 17rem;
  background: white;
  padding: 1.25rem 0;
  border-right: 1px solid #c9d7df;
  overflow-y: scroll;
  min-height: 0;
  height: calc(100vh - (2.9375rem));
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.meetings-type-selector {
  display: flex;
  border-bottom: 0.0625rem solid #c9d7df;
  text-transform: uppercase;
  font-size: 0.9375rem;
  height: 2.5rem;
  align-items: center;
}

.meetings-type-selector > p:first-child {
  border-right: 0.0625rem solid #00000027;
}


.meetings-type-selector > p {
  width: 50%;
  text-align: center;
}

.buttons {
  margin-left: auto;
  display: flex;
}

.buttons > * {
  margin-left: 1rem;
}

.notes > div > div:first-child {
  display: flex;
  justify-content: space-between;
}

.head-text {
  font-weight: 600;
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.719);
  margin-bottom: 0.625rem;
}

.visibility-note {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.596);
}

.public-note, .private-note {
  border-radius: 0.125rem;
  padding: 0.625rem 0.625rem;
  min-height: 6.25rem;
  height: 100%;
  /*overflow: scroll;*/
  font-weight: 400;
  width: 100%;
  color: black;
  align-items: center;
  outline: none;
  cursor: text;
  transition: background-color .4s;
  background: #fff;
  border: 0.0625rem solid #c9d7df;
  padding-right: 0.75rem;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.public-note:hover, .private-note:hover {
  box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #98b1bd;
  border-color: #98b1bd;
}

.public-note:focus, .private-note:focus {
  box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #1482b9 !important;
  border-color: #1482b9 !important;
}

.private-note {
  background: #ffffcd;
}

.shadow-stats {
  display: flex;
}
.shadow-stats .head-text {
  font-weight: normal;
}
.shadow-stats > div > p {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.548);
}
.shadow-stats > div {
  width: 48%;
  margin-bottom: 25px;
  margin-right: 2%;
}

.filter-selectors-2 {
  width: 100%;
  height: 3.125rem;
  background: white;
  border-top-right-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
}

.selectors {
  display: flex;
  height: 100%;
}

.selectors > a {
  text-decoration: none;
  height: 100%;
  padding: 15px 15px;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
}
.selectors > a:hover {
  color: rgba(0, 0, 0, 0.797);
  background-color: #4e5f6e0c;
}
.selectors > a > div {
  color: rgba(0, 0, 0, 0.651);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.0625rem solid transparent;
  transition: color .4s, background-color .4s, box-shadow .4s;
}
.selectors > a:hover > div {
  border-bottom: 0.0625rem solid #0e6794;
}
.selectors > a > div > p {
  font-weight: 600;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.filter-option {
  cursor: pointer;
}
.filter-option-active {
  background-color: #4e5f6e10;
}
.filter-option-active > div {
  /*border-bottom: 0.0625rem solid #0e6794 !important;
  box-shadow: inset 0 -0.1875rem 0 #0e6794;*/
  color: rgba(0, 0, 0, 0.836) !important;

}
.filter-option-active > div > svg {
  fill: #4e5f6e !important;
  fill: #0e6794 !important;
}

.filter-option-count {
  border: 0.0625rem solid #c9d7df;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  padding: 0 0.3125rem;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.651);
}

.pip-note {
  border-radius: 0.125rem;
  padding: 0.625rem 0.625rem;
  min-height: 6.25rem;
  height: 245px;
  margin-top: 20px;
  overflow: scroll;
  font-weight: 400;
  width: 100%;
  color: black;
  align-items: center;
  outline: none;
  cursor: text;
  transition: background-color .4s;
  background: #fff;
  border: 0.0625rem solid #c9d7df;
  padding-right: 0.75rem;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  padding: 30px 30px;
}

.pip-note > p {
  margin-bottom: 20px;
}