.settings-item {
  display: flex;
  align-items: center;
  padding: 0.125rem 1.25rem;
  transition: background-color .4s;
  cursor: pointer;
  color: #637381;
  border-left: 4px solid transparent;

  & > svg {
    min-width: 1.25rem;
    min-height: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
    fill: #6b7d8d;
  }

  &:hover {
    background-color: #919eab18;
  }
  &:hover .settings-item__name{
    color: rgba(0, 0, 0, 0.678) !important;
  }
  &--selected {
    background-color: #919eab18;
    border-left: 4px solid #0e6794 !important;
  }
  &--selected .settings-item__name {
    color: rgba(0, 0, 0, 0.678) !important;
  }
}
.settings-item__name {
  text-transform: none !important;
  padding: 0.65rem 0;
  font-size: 0.935rem !important;
  margin-bottom: 0 !important;
  color: #637381 !important;
}

.settings-item__count {
  margin-left: auto;
  border: 0.0625rem solid #c9d7df;
  font-size: 0.75rem;
  height: 1.25rem;
  padding: 0 5px;
  text-align: center;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.651);
}