.select__arrow {
  font-size: 0;
  height: 0;
  line-height: 0;
  margin-left: 12.8px;
  transition: all .15s ease;
  width: 0;
  border-left: 0.33rem solid transparent;
  border-right: 0.33rem solid transparent;

  &--up {
    border-bottom: 0.33rem solid #4d6072;
  }
  
  &--down {
    border-top: 0.33rem solid #4d6072;
  }
}