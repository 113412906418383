.people {
  width: 100%;
}

.people__header {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  padding-bottom: 0.75rem;
}
.people__header::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -2rem;
  right: -2rem;
  width: 100%;
  margin: 0 auto;
  height: 1.6rem;
  background: linear-gradient(180deg,#f9f9f9 0,rgba(240,244,247,0));
}
.people__header > * {
  margin-bottom: 0.9375rem;
}

.people__selector {
  display: flex;
  padding-top: 1.25rem;
}

.people__select {
  &--selected {
    border-bottom: 1px dotted #696565 !important;
  }
}

.people__select > a {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 0.875rem;
  color: #3e4852;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.people__select > a:hover {
  color: black;
}

.people__buttons {
  margin-left: auto;
  display: flex;
}

.people__buttons > * {
  margin-left: 1rem;
}

.people-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px 0;
}
.people-list > * {
  margin-bottom: 20px;
}