.login-page {
  height: 100vh;
  width: 100%;
  background: #021524f7;
}

.login-section {
  width: 100%;
  background: #021423;
  padding-top: 4em;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

.login-box {
  width: 30rem;
  background: #1e313d;
  margin-bottom: -2em;
  border-radius: 6px;
  padding: 2em;
  box-shadow: 0 1.1px 4.9px rgba(0, 0, 0, 0.05), 0 3.8px 16.3px rgba(0, 0, 0, 0.06), 0 17px 73px rgba(0, 0, 0, 0.07);

  > svg {
    display: block;
    margin: 0 auto;
    width: 3.5rem;
    height: 3.5rem;
    fill: #607d8b;
    transition: fill .4s;
  }
}

.login-hr {
  width: 100%;
  height: 1px;
  border-width: 0;
  background-color: #3b5265;
  margin: 1.5em 0 1.5em 0;
}

.login-input label {
  color: white;
}
.login-input input {
  display: block;
  max-height: 5rem;
  padding: 0.95rem;
  border-radius: 3px;
  border: 1px solid #ffffff;
  margin: 0.625rem 0;
  width: 100%;
  outline: none;
}

.login-button {
  padding: 0.3125rem 0;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  background-color: #0f6695;
  color: white;
  width: 100%;
  font-size: 1.1rem;
  margin: 0.625rem 0;
  max-height: 50px;
}

.login-fgtpw {
  display: block;
  text-align: right;
  color: white;
  color: #c7c4c4;
  margin: 0.625rem 0 0 0;
}

.login-footnote {
  position: relative !important;
  bottom: 0 !important;
  text-align: center !important;
  margin: 0 auto;
  padding: initial !important;
  color: #c7c4c4;
  width: 31.25rem;
  font-size: 0.9375rem;
  line-height: 2rem;
}

.fgt-title {
  text-align: center;
  font-weight: bold;
  color: #c7c4c4;
  font-size: 1.25rem;
}

.fgt-headnote {
  color: white;
  margin: 1.25rem 0;
  line-height: 1.7rem;
}

.fgt-login {
  display: block;
  text-align: center;
  color: white;
  color: #c7c4c4;
  margin: 0.625rem 0 0 0;
  text-decoration: underline;
}

.sent-email {
  color: #ffffff96;
}

.reset-title {
  text-align: center;
  font-weight: bold;
  color: #c7c4c4;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.setup-names {
  display: flex;
  & > input:first-child {
    margin-right: 0.625rem;
  }
}

.setup-form-group {
  display: flex;

  > div {
    width: 100%;
  }
  > div:first-child {
    margin-right: 1.25rem;
  }
}

.setup-disabled {
  opacity: 0.6;
  background: white;
}

.setup-box {
  width: 42.5rem;
  background: #1e313d;
  margin-bottom: -2em;
  border-radius: 6px;
  padding: 2em;
  box-shadow: 0 1.1px 4.9px rgba(0, 0, 0, 0.05), 0 3.8px 16.3px rgba(0, 0, 0, 0.06), 0 17px 73px rgba(0, 0, 0, 0.07);

  > svg {
    display: block;
    margin: 0 auto;
    width: 3.5rem;
    height: 3.5rem;
    fill: #607d8b;
    transition: fill .4s;
  }
}

.role-input {
  text-transform: capitalize;
}

.flash-message {
  color: white;
  margin-bottom: 20px;
  text-align: center;
}
.flash-message > a {
  text-decoration: underline;
  color: inherit;
}