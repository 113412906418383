.input {
  font-weight: 600;
  color: #4d6072;
  line-height: 2.4rem;
  height: 2.8125rem;
  min-height: 2.5rem;
  width: 100%;
  background: #fff;
  margin: 0;
  border: 0.0625rem solid #bccdd7;
  border-radius: 0.125rem;
  padding: 0 0.625rem;
  transition: border .1s ease;
  outline: none;

  &::placeholder {
    color: #4d6072c7;
    font-weight: 400;
  }

  &:hover {
    box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #98b1bd;
    border-color: #98b1bd;
  }

  &:focus {
    box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #1482b9 !important;
    border-color: #1482b9 !important;
  }

  &--disabled {
    opacity: 0.5 !important;
    
    &:hover {
      border: 0.0625rem solid #bccdd7;
    }
  }
}