@mixin gradient {
  background-image: linear-gradient(-180deg,#fff,#f5f8f9 99%);
  border: 0.0625rem solid #c9d7df;
  color: #4d6072;

  svg {
    fill: #283542;
  }

  &:hover {
    background: #c9d7df;
    color: #283542;
  }
}

.button {
  border-radius: 0.1875rem;
  font-weight: bold;
  font-size: 0.875rem;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: 0;
  border: none;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: color .4s, background-color .4s;

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.3rem;
  }
  #loading {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.3rem;
  }

  &--primary {
    background: hsl(201, 82%, 32%);
    color: white;

    svg {
      fill: white;
    }

    &:hover {
      background: #0e5982;
    }

    &.disabled {
      background: #0f669591;
      opacity: .5;
      cursor: default;
    }
  }
  &--transparent {
    color: #4d6072;
    border: 0.0625rem solid #c9d7df;
    background: #e5ebef;
    background: transparent;

    svg {
      fill: #4d6072;
    }

    &:hover {
      background: #c9d7df;
      color: #283542;
    }
  }

  &--danger {
    background: #e45050;
    color: white;
    border: 0.0625rem solid transparent;
  }
  
  &--gradient {
    background-image: linear-gradient(-180deg,#fff,#f5f8f9 99%);
    border: 0.0625rem solid #c9d7df;
    color: #4d6072;

    svg {
      fill: #283542;
    }

    &:hover {
      background: #c9d7df;
      color: #283542;
    }

    &.disabled {
      background: #fff;
      opacity: .5;
      cursor: default;
    }
  }
}

