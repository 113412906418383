.checkbox {
  display: grid;
  grid-template-columns: min-content auto;
}

.checkbox__control {
  display: inline-grid;
  min-width: 1.25rem;
  max-width: 1.25rem;
  min-height: 1.1875rem;
  max-height: 1.1875rem;
  border-radius: 0.125rem;
  border: 2px solid #616060;
  transition: all 0.4s ease-out;
  justify-content: center;
  cursor: pointer;

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
    min-width: 0.875rem;
    max-width: 0.875rem;
    min-height: 0.875rem;
    max-width: 0.875rem;
  }
}

.checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";

  & > * {
    grid-area: checkbox;
  }
  
  input {
    opacity: 0;
    min-width: 1.25rem;
    max-width: 1.25rem;
    min-height: 1.1875rem;
    max-height: 1.1875rem;
    cursor: pointer;

    &:checked + .checkbox__control {
      background-color: #0f6694;
      border: 2px solid #0f6694;
    }
    &:checked + .checkbox__control svg {
      transform: scale(1);
    }
    &:disabled + .checkbox__control {
      opacity: 0.5;
    }
  }
}