.projects {
  display: flex;
  
}

.projects__header {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  padding-bottom: 0.75rem;
  z-index: 99;
}
.projects__header::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -2rem;
  right: -2rem;
  width: 100%;
  margin: 0 auto;
  height: 1.6rem;
  background: linear-gradient(180deg,#f9f9f9 0,rgba(240,244,247,0));
}
.projects__header > * {
  margin-bottom: 0.9375rem;
}

.projects__selector {
  display: flex;
  padding-top: 1.25rem;
  align-items: center;
  margin: 30px 0;
}

.projects__selector > .projects__select {
  margin-right: 1.25rem;
}

.projects__select {
  &--selected {
    border-bottom: 0.0625rem dotted #696565 !important;
  }
}

.projects__select > a {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 0.9375rem;
  color: #3e4852;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.projects__select > a:hover {
  color: black;
}

.projects__buttons {
  margin-left: auto;
  display: flex;
}

.projects__buttons > * {
  margin-left: 1rem;
}

.projects-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem 0;
}
.projects-list > * {
  margin-bottom: 1.25rem;
}

.projects__sidebar {
  position: fixed;
  width: 22%;
  min-width: 17rem;
  background: white;
  padding: 1.25rem 0;
  border-right: 1px solid #c9d7df;
  overflow-y: scroll;
  min-height: 0;
  height: calc(100vh - (3.75rem + 2.9375rem));
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.projects__sidebar::-webkit-scrollbar {
  display: none;
}

.projects__content {
  width: 100%;
  padding: 0 10.5rem;
}
.projects-list-empty {
  border: 2px dashed #b2cedd77;
    border-bottom: none;
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    color: #0000009c;
}