.meeting-filter {
  height: 30.5625rem;
  width: 41.5rem;
  background: #f0f4f7;
  border-radius: 0.125rem;
  overflow: hidden;
  overflow-y: scroll;
}

.filter-selectors {
  width: 100%;
  height: 3.125rem;
  background: white;
  border-bottom: 0.0625rem solid #c9d7df;
  border-top-right-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.filter-selectors-2 {
  width: 100%;
  height: 3.125rem;
  background: white;
  border-top-right-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
}

.selectors {
  display: flex;
  height: 100%;
}

.selectors > a {
  text-decoration: none;
  height: 100%;
  padding: 0 0.9375rem;
  display: flex;
  justify-content: center;
}
.selectors > a:hover {
  color: rgba(0, 0, 0, 0.797);
  background-color: #4e5f6e0c;
}
.selectors > a > div {
  color: rgba(0, 0, 0, 0.651);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.0625rem solid transparent;
  transition: color .4s, background-color .4s, box-shadow .4s;
}
.selectors > a:hover > div {
  border-bottom: 0.0625rem solid #0e6794;
}
.selectors > a > div > p {
  font-weight: 600;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
.filter-option {
  cursor: pointer;
}
.filter-option-active > div {
  border-bottom: 0.0625rem solid #0e6794 !important;
  box-shadow: inset 0 -0.1875rem 0 #0e6794;
  color: rgba(0, 0, 0, 0.836) !important;
}
.filter-option-active > div > svg {
  fill: #4e5f6e !important;
  fill: #0e6794 !important;
}

.filter-option-count {
  border: 0.0625rem solid #c9d7df;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  padding: 0 0.3125rem;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.651);
}

.filter-inputs {
  padding: 1.875rem 2.5rem 1.25rem 2.5rem;
  position: sticky;
  top: 3.125rem;
  z-index: 2;
  background: #f0f4f7;
}

.filter-inputs::after {
  content: "";
  position: absolute;
  top: 100%;
  left: -2rem;
  right: -2rem;
  height: 1.6rem;
  background: linear-gradient(180deg,#f0f4f7 0,rgba(240,244,247,0));
}

.check-all {
  background: #fff;
  display: flex;
  align-items: center;
  border: 0.0625rem solid #c9d7df;
  padding: 0 0.625rem;
  color: #4d6072;
  font-size: 1rem;
  border-radius: 0.1875rem;
  font-weight: 600;
  width: 7.1875rem;
  height: 2.1875rem;
  opacity: 0.5;
  cursor: default;
  margin: 1.25rem 0;
}

.check-all {
  opacity: 1;
  background-image: linear-gradient(-180deg,#fff,#f5f8f9 99%);
  cursor: pointer;
}

.check-all:hover {
  background: #c9d7df;
  color: #283542;
}

.check-all > p {
  margin-left: 0.5rem;
}

.filter-content {
  padding: 0.625rem 2.5rem 1.25rem 2.5rem;
  position: relative;
  z-index: 1;
}

.filter-item {
  width: 100%;
  background: rgba(255, 255, 255, 0.842);
  margin-bottom: 0.625rem;
  border-radius: 0.125rem;
  border: 0.0625rem solid #bccdd7;
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
  display: flex;
  align-items: center;
}

.filter-item-role {
  text-transform: uppercase;
  font-weight: 600;
  margin-left: auto;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.651);
}

.filter-item > .project-item {
  margin-left: 0.625rem;
  color: rgb(85, 85, 85);
}

.filter-item > .people-item {
  margin-left: 0.625rem;
  color: rgb(85, 85, 85);
  display: flex;
  align-items: center;
}

.people-item > div {
  margin-right: 0.3125rem;
}

.create-meeting, .edit-meeting {
  height: 36rem;
  width: 42rem;
  background: #fff;
  border-radius: 0.125rem;
  position: relative;
}

.edit-meeting.select-open {
  overflow: hidden;
}

.edit-meeting-header {
  width: 100%;
  background: white;
  border-bottom: 0.0625rem solid #c9d7df;
  border-top-right-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.edit-header-name {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.4375rem;
  position: relative;
  padding: 20px 20px;
  top: 0.5rem;
}

.create-meeting.select-open {
  overflow: hidden;
}

.create-meeting-header {
  height: 5rem;
  width: 100%;
  background: white;
  /*border-bottom: 0.0625rem solid #c9d7df;*/
  border-top-right-radius: 0.125rem;
  border-top-left-radius: 0.125rem;
  padding: 1.25rem 1.5625rem;
  position: sticky;
  top: 0;
  z-index: 2;
}

.header-name {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.4375rem;
  position: relative;
  top: 0.5rem;
}

.step-count {
  color: rgb(58, 58, 58);
  font-size: 0.875rem;
  margin-right: 0.625rem;
  margin-left: auto;
  width: fit-content;
}

.create-meeting-content {
  padding: 0 2.5rem 1.5625rem 2.5rem;
}

.type-purpose, .date-time, .location {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.type, .purpose,
.date, .time {
  width: 48%;
  margin-bottom: 1.25rem;
}

.address-link {
  width: 100%;
}

.participants > p,
.type > p, 
.purpose > p,
.date > p,
.time > p,
.medium > p,
.address-link > p {
  font-weight: 600;
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.719);
  margin-bottom: 0.625rem;
}

.address-link > input {
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  
}

.time > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.time-opts {
  margin-bottom: 0.625rem;
}
.time-opts > span {
  font-weight: 600;
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.719);
}

.time-opts > span:last-child {
  display: inline;
  padding: 0.0625rem 0.625rem;
  border-radius: 20px;
  background: rgb(0, 0, 0, 0.03);
  border: 0.0625rem solid #c9d7df;
  color: #4d6072;
  text-transform: uppercase;
  font-size: 0.70rem;
  font-weight: bold;
  cursor: pointer;
}

.time-opts > span:last-child:hover {
  background: rgb(0, 0, 0, 0.10);
  background: #c9d7df;
  color: #283542;
}

.from, .to {
  width: 48%;
}

.participants-header {
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem;
  margin: 1.25rem 0;
}

.participants-header > .search-or-selected {
  margin-left: 1.25rem;
}

.project-select {
  width: 100%;
}

.project-select > input {
  border: 0.0625rem solid #c9d7df;
  background: white;
  border-radius: 0.125rem;
  padding: 0.625rem 0.625rem;
  display: flex;
  font-weight: 600;
  width: 100%;
  color: #4d6072;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: background-color .4s;
}
.project-select > input:hover {
  background: #c9d7df;
  color: #283542;
}
.project-select > input::placeholder {
  color: #4d6072;
  opacity: 1;
}

.search-or-selected {
  display: flex;
}

.search-or-selected > a {
  border: 0.0625rem solid #c9d7df;
  border-radius: 0.125rem 0 0 0.125rem;
  padding: 0.625rem 0.625rem;
  display: flex;
  font-weight: 600;
  color: #4d6072;
  align-items: center;
  cursor: pointer;
}

.search-or-selected > a:last-child {
  border-radius: 0 0.125rem 0.125rem 0;
  border-left-color: transparent;
}

.search-or-selected > a > span {
  color: #4d6072;
  margin-left: 0.3125rem;
  font-size: 0.875rem;
}

.selected-selector {
  background: #c9d7df;
  /*background: #c9d7dfad;*/
  color: #283542 !important;
}

.participants-search-container {
  width: 21.1rem;
  position: relative;
}

.search-content {
  padding: 1.25rem 1.25rem 0.3125rem 1.25rem;
  height: 12.5rem;
  background: rgba(255, 255, 255, 0.73);
  position: relative;
  overflow: scroll;
  border: 0.0625rem solid #c9d7df;
  border-radius: 0.125rem;
  margin-bottom: 1.25rem;
}

.search-content .filter-item, .search-content-2 .filter-item {
  background: rgb(229 235 239 / 37%);
  background: none;
  border: none;
  border-bottom: 0.0625rem dashed rgb(0 0 0 / 8%);
  border-bottom: 0.0625rem dashed rgb(201 215 223 / 62%);
}

.search-content .filter-item:last-child, .search-content-2 .filter-item:last-child {
  border-bottom: none;
}

.create-meeting-footer, .edit-meeting-footer {
  width: 100%;
  position: relative;
  bottom: 1rem;
  display: flex;
  align-items: center;
  padding: 0 2.5rem;
}
.edit-meeting-footer {
  position: absolute;
}

.create-meeting-buttons {
  display: flex;
  margin-left: auto;
}

.create-meeting-buttons > * {
  margin-left: 0.7rem;
}

.cancel-button, .continue-button {
  border-radius: 0.1875rem;
  font-weight: bold;
  padding: 0.5rem 0.625rem;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
}

.cancel-button {
  color: #4d6072;
  margin-right: 0.625rem;
  border: 0.0625rem solid #c9d7df;
}

.continue-button {
  background: #0f6694;
  color: white;
}
.cancel-button:hover {
  background: #c9d7df;
  color: #283542;
}
.continue-button:hover {
  background: #0e5982;
}

.summary {
  margin-bottom: 1.25rem;
}
.summary > div {
  display: flex;
  align-items: center;
  margin-bottom: 0.9375rem;
}
.summary > div:last-child {
  padding: 0.625rem 0;
  border-radius: 0.1875rem;
  display: flex;
  margin-right: 1.25rem;
}
.summary > div:last-child > div {
  margin-right: 0.625rem;
}
.summary > div > p {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  color: #4d6072;
  position: relative;
  top: 0.0625rem;
}
.summary > div > svg {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.3125rem;
  fill: #4d6071;
}

.summary-part {
  color: #283542;
}
.summary-details > span {
  margin-right: 0.1875rem;
}
.summary-details > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.1875rem;
  stroke: #4d6071;
  fill: none !important;
}
.summary-details > svg > path {
  stroke-width: 1;
}

.notes > div > div:first-child {
  display: flex;
  justify-content: space-between;
}

.head-text {
  font-weight: 600;
  font-size: 0.9375rem;
  color: rgba(0, 0, 0, 0.719);
  margin-bottom: 0.625rem;
}

.visibility-note {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.596);
}

.public-note, .private-note {
  border-radius: 0.125rem;
  padding: 0.625rem 0.625rem;
  height: 100%;
  font-weight: 400;
  width: 100%;
  color: black;
  align-items: center;
  outline: none;
  cursor: text;
  transition: background-color .4s;
  background: #fff;
  border: 0.0625rem solid #c9d7df;
  padding-right: 0.75rem;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}

.public-note:hover, .private-note:hover {
  box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #98b1bd;
  border-color: #98b1bd;
}

.public-note:focus, .private-note:focus {
  box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #1482b9 !important;
  border-color: #1482b9 !important;
}

.private-note {
  background: #ffffcd;
}

.myschedule-header {
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 1.25rem;
}



.myschedule-header > p {
  color: #283542;
  color: #4d6072;
  color: rgb(0 0 0 / 72%);
  font-weight: 600;
  display: flex;
  cursor: pointer;
  padding: 0 0.25rem 0.25rem 0;
  border-bottom: 0.125rem solid transparent;
  align-items: center;
}


.myschedule-header > p:hover {
  border-bottom: 0.125rem solid #1285c1;
}



.myschedule-header > p > svg {
  fill: rgb(0 0 0 / 72%);
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
}

.myschedule-date {
  position: relative;
  cursor: default !important;
}
.myschedule-date:hover {
  border-bottom: 2px solid transparent !important;
}
.myschedule-date > span {
  text-decoration: underline;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  color: #1285c1;
  cursor: pointer;
  font-weight: 500;
}

.myschedule-date > div {
  position: absolute;
  width: 290px;
  z-index: 100;
  top: 30px;
  font-weight: 500;
}

.myschedule-note {
  font-size: 0.8rem;
  margin-top: 1.25rem;
  text-align: right;
  /*font-style: italic;*/
}

.search-content-2 {
  padding: 0.3rem 1.25rem 0.3rem 1.25rem;
  height: 7rem;
  background: rgba(255, 255, 255, 0.73);
  position: relative;
  overflow: scroll;
  border: 0.0625rem solid #c9d7df;
  border-radius: 0.125rem;
}