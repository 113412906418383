.meeting-schedule {
  //border-top: 1px solid #c9d7df;
  height: 25rem;
  width: 100%;
  //border-radius: 0.25rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.meeting-schedule__content {
  width: 100%;
  color: #4d6072;

  &-header-row {
    position: sticky;
    display: block;
    width: 100%;
    color: #4d6072;
    top: 0;
    z-index: 1;
    background: white;
    display: flex;
    border-bottom: 1px solid #c9d7df;
  }

  &-row {
    display: flex;
    //border-bottom: 1px solid #c9d7df;

    &:last-child {
      border-bottom: none;
    }
  }

  &-header-item {
    //border-right: 1px solid #c9d7df;
    width: 33%;
    text-align: center;
    padding: 0.625rem 0;

    &:last-child {
      border-right: none;
    }
  }

  &-item {
    //border-right: 1px solid #c9d7df;
    width: 33%;
    text-align: center;
    padding: 0.9375rem 0;

    &:first-child {
      border-right: 1px solid #c9d7df;
    }
    &:not(:first-child) {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 600;
      color: #4d6072;
    }
    &:last-child {
      border-right: none;
    }
    & > div {
      display: flex;
      justify-content: center;

      & > span:before {
        color: #1285c1;
        content: '\25CF';
        font-size: 0.75rem;
      }
    }
  }
}