.team2-item {
  border: 0.0625rem solid #bccdd7;
  background: white;
  border-radius: 2px;
  width: 520px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.team2-item__metadata {
  width: 140px;
  line-height: 1.5em;
  
  > div:nth-child(1) {
    margin-top: 10px;
  }
  > div:nth-child(2) {
    margin: 12px 0 10px 0;
  }

  > div {
    display: flex;
    align-items: center;

    > div:nth-child(2) {
      margin-left: 10px;
      
      > p:nth-child(2) {
        color: rgb(92, 92, 92);
      }
    }
  }
}

.team2-item__links {
  display: flex;
  width: 15.625rem;
  flex-wrap: wrap;
  margin-left: 1.875rem;

  > a {
    display: flex;
    align-items: center;
    flex-basis: 50%;
    margin-top: 10px;
    width: 100px;
    color: #5c5c5c;
    margin-bottom: 5px;

    & > svg {
      min-width: 20px;
      width: 20px;
      min-height: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #6b7d8d;
    }
  }
}

.team2-item__supervisor {
  font-size: 15px;
  color: #6b7d8d;
}

.team2-item__count {
  border: 0.0625rem solid #c9d7df;
  margin-right: 0.5rem;
  width: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.75rem;
  padding: 0 0.3125rem;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.651);
}