.alert {
  background-image: linear-gradient(-180deg,#fff,#f5f8f9 99%);
  background: #f3d6d4;
  width: 250px;
  padding: 10px 30px;
  border-radius: 5px;
  opacity: 0.95;
  border-top: 5px solid #f44336;
  position: absolute;
  right: 50px;
  font-size: 0.92rem;
  box-shadow: 0 1.1px 4.9px rgba(0, 0, 0, 0.05), 0 3.8px 16.3px rgba(0, 0, 0, 0.06), 0 17px 73px rgba(0, 0, 0, 0.07);
  line-height: 1.3rem;
  color: #991f15;
  animation-name: fadeIn;
  animation-duration: 2s;
  z-index: 99999999999999;
}

@keyframes fadeIn {
  from {opacity: 0} 
  to {opacity: 1}
}

@keyframes fadeOut {
  from {opacity: 1} 
  to {opacity: 0}
}