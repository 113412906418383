.select {
  position: relative;
  height: 2.5rem;
}
.select__button {
  border-radius: 0.125rem;
  padding: 0.625rem 0.625rem;
  height: 100%;
  display: flex;
  font-weight: 400;
  width: 100%;
  color: black;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: background-color .4s;
  background: #fff;
  border: 0.0625rem solid #c9d7df;
  padding-right: 0.75rem;
  justify-content: space-between;

  &--highlight {
    box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #1482b9 !important;
    border-color: #1482b9 !important;
  }

  &:hover {
    box-shadow: inset 0 0.0625rem 0.0625rem 0 transparent, 0 0 0 0.0625rem #98b1bd;
    border-color: #98b1bd;
  }
}


.select__arrow {
  font-size: 0;
  height: 0;
  line-height: 0;
  margin-left: 12.8px;
  transition: all .15s ease;
  width: 0;
  border-left: 0.33rem solid transparent;
  border-right: 0.33rem solid transparent;

  &--up {
    border-bottom: 0.33rem solid #4d6072;
  }
  
  &--down {
    border-top: 0.33rem solid #4d6072;
  }
}

.select__dropdown {
  display: none;
  width: 100%;
  position: absolute;
  z-index: 1;

  &--show {
    display: block;
  }
}