.team-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: background-color .4s;
  cursor: pointer;
  color: #637381;
  border-left: 4px solid transparent;


  &:hover {
    background-color: #919eab18;
  }
  &:hover .team-item__name{
    color: rgba(0, 0, 0, 0.678) !important;
  }
  &--selected {
    background-color: #919eab18;
    border-left: 4px solid #0e6794 !important;
  }
  &--selected .team-item__name {
    color: rgba(0, 0, 0, 0.678) !important;
  }
}
.team-item__name {
  text-transform: none !important;
  padding: 0.65rem 0;
  font-size: 0.935rem !important;
  margin-bottom: 0 !important;
  color: #637381 !important;
}

.team-item__count {
  border: 0.0625rem solid #c9d7df;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  text-align: center;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.651);
}