@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust:none;
  font-family: 'Source Sans Pro', sans-serif;
}

span, div {
  user-select: none; 
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  width: 100%;
  height: 100%;
  background-color: #f3f6f8;
  background-color: #f9f9f9;
}
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@-moz-document url-prefix() {
  select, select:-moz-focusring, select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    border:0;
  }
}

.body-no-scroll {
  height: 100%;
  overflow: hidden;
}

.content {
  position: relative;
  /*top: calc(3.75rem + 2.9375rem);*/
  top: 2.9375rem;
  width: 100%;
  height: calc(100vh - (2.9375rem));
  overflow: scroll;
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
  
}

@media only screen and (min-width: 961px) {
}

@media only screen and (min-width: 1281px) {
  html {
    font-size: calc(16px + (20 - 16) * (((var(--vw, 1vw) * 100) - 1200px) / (1600 - 1200)));
    font-size: 110%;
  }
}

@media only screen and (min-width: 1441px) {
  html {
    font-size: calc(16px + (20 - 16) * (((var(--vw, 1vw) * 100) - 1200px) / (1600 - 1200)));
    font-size: 120%;
  }
}