.people-item {
  border: 0.0625rem solid #bccdd7;
  background: white;
  border-radius: 2px;
  width: 520px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.people-item__metadata {
  > div:nth-child(1) {
    margin-top: 10px;
  }
  > div:nth-child(2) {
    margin: 12px 0 10px 0;
  }

  > div {
    display: flex;
    align-items: center;

    > div:nth-child(2) {
      margin-left: 10px;
      
      > p:nth-child(2) {
        color: rgb(92, 92, 92);
      }
    }
  }
}

.people-item__links {
  display: flex;
  width: 250px;
  flex-wrap: wrap;
  margin-left: 30px;

  > a {
    display: flex;
    flex-basis: 50%;
    margin-top: 10px;
    width: 100px;
    color: #5c5c5c;
    margin-bottom: 5px;

    & > svg {
      min-width: 20px;
      width: 20px;
      min-height: 20px;
      height: 20px;
      margin-right: 8px;
      fill: #6b7d8d;
    }
  }
}

.people-item__supervisor {
  font-size: 15px;
  color: #6b7d8d;
  display: none;
}