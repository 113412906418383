.menu-container {
  width: 100%;
  background-color: #2c2e38;
  height: 3.75rem;
  display: flex;
  position: fixed;
  z-index: 2;
}
.left-side {
  margin-right: auto;
  border-right: 0.0625rem solid #454f5b;
  min-width: 4.375rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-side > a > svg {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  fill: #607D8B;
  transition: fill .4s;
}
.left-side > a > svg:hover {
  fill: url(#linear-gradient);
  fill: #ef7b33;
}
.settings-active > svg {
  fill: #ef7b33 !important;
}
.menu-options {
  display: flex;
  width: 100%;
  padding: 0 1.25rem;
  align-items: center;
}
.menu-options > a {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 1.125rem;
  color: #919eab;
  font-weight: 400;
  margin-right: 1.25rem;
  cursor: pointer;
  transition: color .4s, background-color .4s;
  margin-right: 0.3125rem;
  border-radius: 0.5rem;
  padding: 0.4375rem 0.9375rem;
}
.menu-options > a:hover {
  color: white;
  background-color: #363c4c;
}
.menu-option-active { 
  color: white !important;
  background-color: #363c4c;
}

.submenu-container {
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  height: 2.9375rem;
  /*padding: 0 1.25rem;*/
  border-bottom: 0.0625rem solid #00000027;
  border-bottom: 0.0625rem solid #c9d7df;
  position: fixed;
  /*top: 3.75rem;*/
  z-index: 999999;
}
.submenu-container > a {
  text-decoration: none;
  height: 100%;
  padding: 0 0.9375rem;
  display: flex;
  justify-content: center;
  transition: color .4s, background-color .4s, box-shadow .4s;
}
.submenu-container > a:hover {
  /*color: rgba(0, 0, 0, 0.797);
  background-color: #4e5f6e10;*/
}
.submenu-container > a > div {
  color: rgba(0, 0, 0, 0.651);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.12rem solid transparent;
  transition: box-shadow .2s;
}
.submenu-container > a:hover > div {
  border-bottom: 0.12rem solid #0e6794;
}
.submenu-container > a > div > svg {
  min-width: 1.25rem;
  min-height: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  fill: #6b7d8d;
}
.submenu-container > a > div > p {
  font-weight: 600;
  font-size: 0.8rem;
  position: relative;
  top: 0.0625rem;
  text-transform: uppercase;
}
.suboption-active {
  color: rgba(0, 0, 0, 0.797);
  background-color: #4e5f6e10;
}
.suboption-active > div {
  border-bottom: 0.12rem solid #0e6794 !important;
  /*box-shadow: inset 0 -0.125rem 0 #0e6794;*/
  color: rgba(0, 0, 0, 0.836) !important;

}
.suboption-active > div > svg {
  fill: #4e5f6e !important;
  fill: #0e6794 !important;
  fill: #3a556e !important;
}

.create-button-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  margin-right: 0.625rem;
}
.create-button {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-button > svg {
  width: 1.7rem;
  height: 1.7rem;
  fill: none;
  stroke: #6b7d8d;
  cursor: pointer;
  transition: fill .4s;
}
.create-button > svg:hover {
  fill: #373c41;
  stroke: #373c41;
}
.dropdown-container {
  z-index: 999;
  width: 11.25rem;
  position: absolute;
  background: #fff;
  top: calc(100% + 0.875rem);
  right: -20px;
  padding-top: 0.625rem;
  -webkit-box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0,0,0,.15);
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0,0,0,.15);
  border-radius: 0.25rem;
}
.dropdown-container > p {
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.726);
}
.dropdown-options {
}
.create-button-option {
  display: flex;
  align-items: center;
  padding: 0.75rem 0 0.75rem 1.25rem;
  cursor: pointer;
  text-decoration: none;
  color: #637381;
  font-size: 1rem;
  transition: background-color .4s;
}

.create-button-option:hover {
  background-color: #919eab18;
}
.create-button-option > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  fill: #6b7d8d;
}
.create-button-option:last-child {
  border-bottom: none;
}
.right-side {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
}
.settings-dropdown {
  position: relative;
  cursor: pointer;
}

.settings-dropdown-container {
  z-index: 999;
  width: 11.25rem;
  position: absolute;
  background: #fff;
  top: calc(100% + 0.875rem);
  right: 1.25rem;
  padding: 0.625rem 0;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0,0,0,.15);
  border-radius: 0.25rem;
  cursor: default;
}
.settings-dropdown-header {
  border-bottom:0.0625rem solid rgba(0, 0, 0, 0.068);
  padding: 0.625rem 1.25rem 0.8125rem 1.25rem;
}
.settings-dropdown-header > p:first-child {
  font-weight: 700;
  margin-bottom: 0.4375rem;
  color: rgba(0, 0, 0, 0.726);
}
.settings-dropdown-header > p:nth-child(2) {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.726);
}
.settings-dropdown-options {
  display: flex;
  flex-direction: column;
  padding-top: 0.625rem;
}
.settings-option {
  padding: 0.625rem 1.25rem;  
  transition: background-color .4s;
  text-decoration: none;
  color: #637381;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.settings-option > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  fill: #6b7d8d;
}
.settings-option:hover {
  background-color: #919eab18;
}

.filter-option-count {
  border: 0.0625rem solid #c9d7df;
  margin-left: 0.5rem;
  font-size: 0.75rem;
  padding: 0 0.3125rem;
  border-radius: 0.125rem;
  color: rgba(0, 0, 0, 0.651);
}

.settings-button {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.settings-button > svg {
  width: 1.7rem;
  height: 1.7rem;
  fill: none;
  stroke: #6b7d8d;
  cursor: pointer;
  transition: fill .4s;
}

.settings-button:hover > svg {
  fill: #373c41;
  stroke: #373c41;
}
.settings-button-active > svg {
  fill: #373c41;
  stroke: #373c41;
}
.create-button-active > svg {
  fill: #373c41;
  stroke: #373c41;
}